.Challenge-grid {
  display: grid;
  grid-template-columns: auto 268px;
  grid-template-rows: repeat(2, auto);
  gap: 2rem;
  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    gap: 0.61rem;
  }
}

.Challenge-progress{
  grid-column: 1;
  grid-row: 1;
}
.Challenge-stats{
  grid-column: 1;
  grid-row: 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2rem;
  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
    gap: 0.61rem;
  }
}
.Challenge-timer{
  grid-column: 2;
  grid-row: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1023px) {
    grid-column: 1;
    grid-row: 3;
    gap: 2.7rem;
  }
}

.Challenge-timer-subblock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  border-radius: 1.5rem;
  padding: 1.4rem 1.5rem;
  color: var(--pallete-rewards-inactive);
  background-color: var(--pallete-background-3);
}

.circular-progress {
  --size: 45px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 3px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * var(--pi) * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);
  @media screen and (max-width: 767px) {
    --size: 25px;
  }
}

.circular-progress circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular-progress circle.bg {
  stroke: var(--pallete-border);
}

.circular-progress circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 0s;
  stroke: var(--pallete-main);
}